<template>

<div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
    <hb-header :divider="false" full>
        <hb-page-header
            title="Leads"
        >
        </hb-page-header>
    </hb-header>
    <div style="flex: 1">
        <hb-report
            :key="report_key"
            report_type="active_leads"
            :actions_panel="['advanced', 'filters', 'export', 'columns', 'save']"
            show_views
            right_click
            row_click="contact_view"
            show_search
            @rowClicked="viewLead"
            show_default_report_option="true"
            ref="HummingbirdReportViewer"
        ></hb-report>
    </div>

</div>
</template>


<script type="text/babel">
    import HbReport from '../assets/HummingbirdReportViewer.vue';
    import { EventBus } from "../../EventBus.js";
    export default {
        name: "Tenants",
        data() {
            return {
                report_key: 0,
            }
        },
        components:{
            HbReport

        },
        filters:{

        },
        computed:{

        },
        created(){
              EventBus.$on('handleClick', this.handleClick);
              EventBus.$on('refetch_data', () => this.fetchData());
        },
        destroyed(){
              EventBus.$off('handleClick', this.handleClick);
              EventBus.$off('refetch_data', () => this.fetchData());
        },

        methods:{
            fetchData(){
                this.report_key++;
            },
            viewLead(data){
                this.$store.dispatch('navigationStore/openSlideOut', {
                    component: 'contact',
                    props: {
                        contact_id: data.lead_id,
                        view: "lead"
                    }
                });
            },
            handleClick(id){
                let payload = {
                    data:{
                        lead_id:id
                    }
                }
                this.$refs.HummingbirdReportViewer.handleRowClick(payload);
            }
        },

        watch:{
        }

    }
</script>
<style scoped>
    .report-section-content{
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      justify-content: stretch;

    }
</style>
